import React, { useState, useCallback } from 'react'
import {
  Marker as GMMarker,
  MarkerProps,
  InfoWindow,
} from '@react-google-maps/api'
import { Typography, Link } from '@material-ui/core'
import parse from 'html-react-parser'

import classes from './marker.module.scss'

interface Props extends MarkerProps {
  description: string
  address: string
  city: string
}

export const Marker = React.memo(
  ({ position, description, address, city, ...props }: Props) => {
    const [showInfo, setState] = useState(false)

    const onClick = useCallback(() => {
      setState(!showInfo)
    }, [showInfo])

    return (
      <div>
        <GMMarker position={position} onClick={onClick} {...props} />

        {showInfo && (
          <InfoWindow position={position} onCloseClick={onClick}>
            <div>
              <Typography variant={'h6'}>{city}</Typography>

              <Typography color={'textSecondary'}>{parse(address)}</Typography>

              <Typography
                variant={'body2'}
                component={'p'}
                dangerouslySetInnerHTML={{ __html: description }}
                className={classes.description}
              />

              <Link
                href={`https://www.google.com/maps/search/perdormire/@${position.lat},${position.lng},17z/data=!3m1!4b1`}
                target={'_blank'}
                rel={'noreferrer noopener'}
              >
                Vedi su google maps
              </Link>
            </div>
          </InfoWindow>
        )}
      </div>
    )
  }
)
