import React from 'react'

import { Map } from '../Map'

import { StoreLocation } from './types'
import { LoadScript } from '@react-google-maps/api'

const libraries = ['drawing', 'visualization', 'places']

interface State {
  fetching: boolean
  storeLocations: StoreLocation[]
  error: boolean
}

export class App extends React.Component<{}, State> {
  state = {
    fetching: true,
    storeLocations: [],
    error: false,
  }

  componentDidMount(): void {
    window.gapi.load('client', this.initGoogleClient)
  }

  initGoogleClient = async () => {
    await window.gapi.client.init({
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      discoveryDocs: [
        'https://sheets.googleapis.com/$discovery/rest?version=v4',
      ],
      scope: 'https://www.googleapis.com/auth/spreadsheets.readonly',
    })

    this.fetchStoreLocations()
  }

  fetchStoreLocations = async () => {
    this.setState({ fetching: true, error: false })

    try {
      const {
        result,
      } = await window.gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: process.env.REACT_APP_STORE_LOCATIONS_SPREADSHEET_ID,
        range: 'A1:Z999',
      })

      if (result.values) {
        this.setState((prevState) => ({
          ...prevState,
          storeLocations: (result.values as string[][])
            .slice(1)
            .map(
              ([
                id,
                name,
                state,
                city,
                cap,
                address,
                lat,
                lng,
                description,
              ]) => ({
                id,
                name,
                state,
                city,
                cap,
                address,
                position: {
                  lat: parseFloat(lat),
                  lng: parseFloat(lng),
                },
                description,
              })
            ),
        }))
      }
    } catch {
      this.setState({ error: true })
    } finally {
      this.setState({ fetching: true })
    }
  }

  render() {
    const { storeLocations } = this.state

    return (
      <LoadScript
        id={'script-loader'}
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={libraries}
        preventGoogleFontsLoading
      >
        <Map storeLocations={storeLocations} />
      </LoadScript>
    )
  }
}
