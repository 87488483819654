import React from 'react'
import { GoogleMap } from '@react-google-maps/api'

import { SideBar } from '../SideBar'
import { Marker } from '../Marker'
import { mapTypeStyles } from './mapTypeStyles'

import { StoreLocation } from '../App/types'

interface Props {
  storeLocations: StoreLocation[]
}

interface State {
  userLocation: google.maps.LatLngLiteral
  searchLocation: google.maps.LatLngLiteral | null
  loading: boolean
}

export class Map extends React.Component<Props, State> {
  state = {
    userLocation: {
      lat: 45.464211,
      lng: 9.191383,
    },
    searchLocation: null,
    loading: true,
  }

  componentDidMount(): void {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords

        this.setState({
          userLocation: {
            lat: latitude,
            lng: longitude,
          },
          loading: false,
        })
      },
      () => {
        this.setState({ loading: false })
      },
      {
        timeout: 5000,
        enableHighAccuracy: false,
        maximumAge: 0,
      }
    )
  }

  setLocation = (location: google.maps.LatLngLiteral): void => {
    this.setState({
      searchLocation: location,
    })
  }

  getCurrentLocation = (): google.maps.LatLngLiteral => {
    const { userLocation, searchLocation } = this.state

    return searchLocation || userLocation
  }

  render() {
    const { loading } = this.state

    if (loading) {
      return null
    }

    const { storeLocations } = this.props
    const location = this.getCurrentLocation()

    return (
      <GoogleMap
        id={'store-locator-map'}
        mapContainerStyle={{ height: '100vh', width: '100vw' }}
        center={location}
        zoom={10}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          styles: mapTypeStyles,
        }}
      >
        <SideBar
          setLocation={this.setLocation}
          location={location}
          storeLocations={storeLocations}
        />

        <div>
          {storeLocations.map(({ position, city, description, address }) => (
            <Marker
              position={position}
              description={description}
              address={address}
              city={city}
              key={`${position.lat}-${position.lng}`}
            />
          ))}
        </div>
      </GoogleMap>
    )
  }
}
