import React, { useState, useCallback } from 'react'
import { Divider, Paper, Button, Collapse } from '@material-ui/core'
import { useGoogleMap } from '@react-google-maps/api'

import { Search } from '../Search'
import { StoresList } from '../StoresList'

import { StoreLocation } from '../App/types'

import classes from './sidebar.module.scss'

interface Props {
  setLocation: (location: google.maps.LatLngLiteral) => void
  location: google.maps.LatLngLiteral
  storeLocations: StoreLocation[]
}

export const SideBar = ({ setLocation, location, storeLocations }: Props) => {
  const [showList, setListShowState] = useState(window.innerWidth >= 768)
  const map = useGoogleMap()
  const setLocationExtended = useCallback(
    (location: google.maps.LatLngLiteral, zoom = 10) => {
      setLocation(location)

      if (window.innerWidth < 768) {
        setListShowState(false)
      }

      if (map) {
        map.setZoom(zoom)
      }
    },
    [setLocation, map]
  )
  const setSearchLocation = useCallback(
    (place: google.maps.places.PlaceResult) => {
      if (place.geometry) {
        setLocationExtended(place.geometry.location.toJSON())
      }
    },
    [setLocationExtended]
  )

  return (
    <Paper className={classes.sideBar} square={true}>
      <Search onChange={setSearchLocation} />

      <Divider />

      <Button
        color={'primary'}
        className={classes.showListButton}
        onClick={() => setListShowState(!showList)}
      >
        {showList ? 'Nascondi ' : 'Mostra '}
        punti vendita
      </Button>

      <Collapse in={showList} unmountOnExit>
        <StoresList
          location={location}
          setLocation={setLocationExtended}
          storeLocations={storeLocations}
        />
      </Collapse>
    </Paper>
  )
}
