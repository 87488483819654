import React from 'react'
import { Autocomplete } from '@react-google-maps/api'
import { InputBase, IconButton, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import classes from './search.module.css'

const canUseAutocomplete = (
  autocomplete: google.maps.places.Autocomplete | null
): autocomplete is google.maps.places.Autocomplete => {
  return autocomplete !== undefined
}

interface Props {
  onChange: (place: google.maps.places.PlaceResult) => void
}

interface State {
  autocomplete: google.maps.places.Autocomplete | null
}

export class Search extends React.Component<Props, State> {
  state = {
    autocomplete: null,
  }

  inputRef = React.createRef<HTMLInputElement>()

  onLoad = (autocomplete: google.maps.places.Autocomplete): void => {
    this.setState({ autocomplete })
  }

  onPlaceChanged = () => {
    const { autocomplete } = this.state
    const { onChange } = this.props

    if (canUseAutocomplete(autocomplete)) {
      onChange((autocomplete! as google.maps.places.Autocomplete).getPlace())
    }
  }

  searchPlace = (input: HTMLInputElement) => {
    const selectedElement = document.querySelector('.pac-item-selected')

    if (!selectedElement) {
      const ScrollDownEvent = new KeyboardEvent('keydown', {
        code: 'ArrowDown',
        key: 'ArrowDown',
        // @ts-ignore
        keyCode: 40,
      })
      const SearchEvent = new KeyboardEvent('keydown', {
        code: 'Enter',
        key: 'Enter',
        // @ts-ignore
        keyCode: 13,
      })

      google.maps.event.trigger(input, 'keydown', ScrollDownEvent)
      google.maps.event.trigger(input, 'keydown', SearchEvent)
    }
  }

  onEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.keyCode === 13) {
      this.searchPlace(event.currentTarget)
    }
  }

  onClick = (): void => {
    if (this.inputRef.current) {
      this.searchPlace(this.inputRef.current)
    }
  }

  render() {
    return (
      <Autocomplete
        onLoad={this.onLoad}
        onPlaceChanged={this.onPlaceChanged}
        restrictions={{ country: 'it' }}
      >
        <div className={classes.paper}>
          <InputBase
            inputRef={this.inputRef}
            onKeyDown={this.onEnter}
            className={classes.input}
            endAdornment={
              <InputAdornment position={'end'}>
                <IconButton onClick={this.onClick} aria-label={'search'}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      </Autocomplete>
    )
  }
}
