import React from 'react'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

import classes from './stores-list.module.css'

import { StoreLocation } from '../App/types'

interface Props {
  location: google.maps.LatLngLiteral
  setLocation: (location: google.maps.LatLngLiteral, zoom: number) => void
  storeLocations: StoreLocation[]
}

const getDistance = (
  a: google.maps.LatLngLiteral,
  b: google.maps.LatLngLiteral
) => {
  const p = 0.017453292519943295 // Math.PI / 180
  const cos = Math.cos
  const distance =
    0.5 -
    cos((b.lat - a.lat) * p) / 2 +
    (cos(a.lat * p) * cos(b.lat * p) * (1 - cos((b.lng - a.lng) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(distance)) // 2 * R; R = 6371 km
}

const sortStoresList = (
  list: StoreLocation[],
  location: google.maps.LatLngLiteral
) => {
  return list.sort((a, b) => {
    return getDistance(a.position, location) - getDistance(b.position, location)
  })
}

export const StoresList = ({
  location,
  setLocation,
  storeLocations,
}: Props) => {
  const list = sortStoresList(storeLocations, location)

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const { id, position, name, address } = list[index]
    const distance = getDistance(location, position)

    return (
      <ListItem
        style={style}
        button
        onClick={() => setLocation(position, 15)}
        key={`${id}-${name}`}
      >
        <ListItemText
          primary={name}
          primaryTypographyProps={{ className: classes.text }}
          secondary={
            <>
              <Typography
                component='span'
                variant='body2'
                className={classes.inline}
                color='textPrimary'
              >
                {distance > 0 && `${distance.toFixed(2)}km - `}
              </Typography>
              {address}
            </>
          }
          secondaryTypographyProps={{ className: classes.text }}
        />
      </ListItem>
    )
  }

  return (
    <div className={classes.paper}>
      <List>
        <FixedSizeList
          itemSize={60}
          height={window.innerHeight - 100}
          itemCount={list.length}
          width={'100%'}
        >
          {renderRow}
        </FixedSizeList>
      </List>
    </div>
  )
}
